
import Select from '@/components/Select.vue';
import { defineComponent, PropType } from 'vue';
import { RegisterListParams } from './index.vue';

export default defineComponent({
  name: 'ListFilter',
  components: {
    Select,
  },
  props: {
    params: {
      type: Object as PropType<RegisterListParams>,
      required: true,
    },
    handleChangeFilterParams: { type: Function, required: true },
    onClickApply: { type: Function, required: true },
    onClickSearch: { type: Function, required: true },
    isFilterOn: Boolean,
  },
  methods: {},
  setup(props) {
    const registerTypeOptions = [
      { value: '', label: '전체' },
      { value: 'productId', label: 'product #' },
      { value: 'memberId', label: 'member #' },
      { value: 'titleKr', label: '타이틀' },
      { value: 'artistNameKr', label: '작가명' },
      { value: 'eventCode', label: '이벤트 코드' },
      { value: 'eventCategory', label: '이벤트 카테고리' },
      { value: 'email', label: '고객 이메일' },      
    ];
    const updateParams = (key: string, value: any) => {
      if(key == 'action' && props.params.key == '') {
        alert('검색 조건을 지정해 주세요.')
        return false;
      }
      props.handleChangeFilterParams(key, value);
    };

    return {
      updateParams,
      registerTypeOptions,
    };
  },
});
