<template>
  <form @submit.prevent="onClickSearch">
    <div class="ListFilter">
      <div class="FilterItem">
        <label for="memberType">검색 조건</label>
        <Select
          placeholder="전체"
          :value="params.key"
          :onChange="(e) => {
       
             updateParams('key', e.target.value)
             //props.params.key = e.target.value
          
          }"
          :options="registerTypeOptions"
          :usePlaceholder="false"
        ></Select>
      </div>
      <input
        type="text"
        class="
          Filter__textInput
          border border-black
          h-10
          ml-3
          py-3
          px-10
          text-xs
        "
        placeholder="텍스트 검색"
        :value="params.keyword"
        @change="(e) => updateParams('keyword', e.target.value)"  
      />
      
      <button
        class="w-30 h-10 ml-4 bg-black text-white text-xs"
        type="submit"
        @click="updateParams('action','submit')"
      >
        검색
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import Select from '@/components/Select.vue';
import { defineComponent, PropType } from 'vue';
import { RegisterListParams } from './index.vue';

export default defineComponent({
  name: 'ListFilter',
  components: {
    Select,
  },
  props: {
    params: {
      type: Object as PropType<RegisterListParams>,
      required: true,
    },
    handleChangeFilterParams: { type: Function, required: true },
    onClickApply: { type: Function, required: true },
    onClickSearch: { type: Function, required: true },
    isFilterOn: Boolean,
  },
  methods: {},
  setup(props) {
    const registerTypeOptions = [
      { value: '', label: '전체' },
      { value: 'productId', label: 'product #' },
      { value: 'memberId', label: 'member #' },
      { value: 'titleKr', label: '타이틀' },
      { value: 'artistNameKr', label: '작가명' },
      { value: 'eventCode', label: '이벤트 코드' },
      { value: 'eventCategory', label: '이벤트 카테고리' },
      { value: 'email', label: '고객 이메일' },      
    ];
    const updateParams = (key: string, value: any) => {
      if(key == 'action' && props.params.key == '') {
        alert('검색 조건을 지정해 주세요.')
        return false;
      }
      props.handleChangeFilterParams(key, value);
    };

    return {
      updateParams,
      registerTypeOptions,
    };
  },
});
</script>

<style lang="scss" scoped>
.ListFilter {
  display: flex;
  align-items: flex-end;
}

.FilterItem {
  width: 150px;
  display: inline-flex;
  flex-direction: column;

  & + & {
    margin-left: 6px;
  }

  & > label {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
  }

  & > input {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    background: #ffffff;
    border: 1px solid #dddddd;
    padding: 0 16px;
    height: 40px;
    background: url('../../../assets/ico_selectbar_arrow.png') no-repeat;
    background-size: 10px;
    background-position: calc(100% - 14px) center;

    &.isNotSelected {
      color: #999;
    }
  }

  & > input {
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  & > select {
    &.isNotSelected {
      color: #999;
    }
  }
}

.Filter__applyButton {
  width: 110px;
  text-align: left;
  margin-left: 6px;
  font-size: 14px;
  height: 40px;
  padding-left: 44px;
  background-size: 28px;
  background-image: url('../../../assets/ico_filter_default.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  box-sizing: border-box;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #ff6363;
    background-image: url('../../../assets/ico_filter_hover.svg');
  }

  &.isApplied {
    background-color: #ff6363;
    background-image: url('../../../assets/ico_filter_applied.svg');
    color: #fff;
  }
}

.Filter__textInput {
  background-image: url('../../../assets/ico_Search.svg');
  background-repeat: no-repeat;
  background-position: 14px center;
  &::placeholder {
    color: #888;
  }
}

</style>
