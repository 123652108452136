
import { defineComponent, ref, onMounted, watch, reactive, toRef } from 'vue';
import { useRoute } from 'vue-router';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import ListFilter from './ListFilter.vue';
import partnerAPI from '@/service/partnerAPI';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';
import { data } from 'msw/lib/types/context';

export type RegisterListParams = {
  key?: any;
  keyword?:any;
  page: number;
  pageSize: number;
};

export default defineComponent({
  name: 'registerTokenList',
  components: {
    Container,
    ListFilter,
    Pagination,
    IconBase,
    RemoveIcon,
    CheckCircleIcon
  },
  props: {},

  setup() {
    const route = useRoute();
    const query = route.query;

    const registerCreateData = ref({
      productId : '',
      memberId :  ''
    });

    const filterParams = reactive<RegisterListParams>({
      keyword: (query.keyword as string) || '',
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
    });

    const registerList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    }) as any;


    const handleChangeFilterParams = (key: string, value: any) => {
      console.log('key : ',key)
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };
    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };

    const handleAllCheck = (e) => {
      console.log(registerList.value.data.content)
      // console.log(data)
      registerList.value.data.content = registerList.value.data.content.map(data => {
        return {
          ...data,
          checked: e.target.checked
        };
      });
    };

    // 체크 박스 event
    const handleCheck = (e, idx) => {
      console.log('cc', e.target.checked, idx);
      registerList.value.data.content[idx].checked = e.target.checked;
    };

    // 레지스터 토큰 등록
    const handleRegister = async (inputData) => {
      // 유효성 검사
      if(inputData.productId=='') {
        alert('Product ID를 입력해 주세요.')
        return false;
      } else if(inputData.memberId == '') {
        alert('Member ID를 입력해 주세요.')
        return false;
      }

      try {
        const { data } = await partnerAPI.adminRegisterToken.adminRegisterTokenCreate({
          adminRegisterTokenCreateDTOList : [inputData]
        });
        fetchRegisterList();
        alert('등록 되었습니다.');
      }catch (e) {
        alert(e.response.data.message)
        console.error(e);
      }
    }

    // 토큰 삭제
    const handleDelete = async () => {
      try {
        // 체크 되어 있는 레지스터 리스트 변수화
        const checkedData = registerList.value.data.content.filter(d => d.checked === true).map(d => d.registerTokenId) || [];
        console.log(checkedData)
        if (checkedData.length === 0) {
          alert('선택된 행이 없습니다.');
          return;
        }
        if(window.confirm('삭제 하시겠습니까?') == false) return false;
        await partnerAPI.adminRegisterToken.adminRegisterTokenDelete({
          registerTokenIds: checkedData
        })

        fetchRegisterList();
        alert('삭제 되었습니다.');
      } catch (e) {
        console.error(e);
      }
    };


    /**
     * 검색 실행
     */
    const executeSearch = () => {
      router.push({
        path: window.location.pathname,
        query: {
          keyword: filterParams.keyword || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
        },
      });
    };

    // 검색
    const getAppliedFilterParams = () => {
      const paramData = {
        page: filterParams.page,
        pageSize: filterParams.pageSize,
      }
      // 검색 조건에 값이 존재할 경우
      if(filterParams.keyword != ''){
        paramData[filterParams.key] = filterParams.keyword
      }
      console.log(filterParams.key)
      return paramData;
    }

    const fetchRegisterList = async () => {
      console.group('fetchRegisterList');
      console.log('finalFilterParams', getAppliedFilterParams());
      console.groupEnd();
        console.log(partnerAPI)
      try {
        const { data } = await partnerAPI.adminRegisterToken.adminRegisterTokenFind(
            getAppliedFilterParams()
        );
        registerList.value = data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };

    onMounted(fetchRegisterList);

    watch([filterParams], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchRegisterList();
    });

    return {
      filterParams,
      registerList,
      handleCheck, // 삭제 checkbox
      handleDelete, // 삭제 메소드
      handleRegister, // 생성 메소드
      registerCreateData, // 생성 param
      handleChangePage,
      handleChangePageSize,
      executeSearch,
      handleChangeFilterParams,
      fetchRegisterList,
      handleAllCheck
    };
  },
});
