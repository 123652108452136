<template>
  <Container>
    <div class="my-5 flex">
        <input type="number" class="border border-black h-10 w-48 px-4 text-sm admin-input" placeholder="Product ID"  v-model="registerCreateData.productId">
        <input type="number" class="border border-black h-10 w-48 px-4 text-sm admin-input ml-2" placeholder="Member ID" v-model="registerCreateData.memberId">
        <button class="w-30 h-10 ml-2 border border-black shadow flex items-center justify-center admin-button"
          @click="handleRegister(registerCreateData)"
        >
        <IconBase>
            <CheckCircleIcon></CheckCircleIcon>
        </IconBase>
        <span class="ml-2">토큰 등록</span>
        </button>
    </div>

    <hr class="mb-3">

    <!-- Filter -->
    <ListFilter
        :params="filterParams"
        :handleChangeFilterParams="handleChangeFilterParams"
        :onClickSearch="executeSearch"
    />

<table
    v-if="registerList"
    class="ListTable table-fixed text-center mt-6 w-full"
  >
    <thead class="bg-gray-200">
      <tr class="h-10">
        <th class="w-10 border-t border-black border-b">
          <div class="flex items-center pl-2 justify-center">
            <input
              type="checkbox"
              class="w-4 h-4 checkbox block mr-2 rounded border-gray-300 text-blue-sky focus:ring focus:ring-offset-0 focus:ring-blue-sky focus:ring-opacity-10"
              @click="handleAllCheck"
             />
            No.
          </div>
        </th>
        <th class="w-10 border-t border-black border-b text-sm">product #</th>
        <th class="w-10 border-t border-black border-b text-sm">member #</th>
        <th class="w-10 border-t border-black border-b text-sm">타이틀</th>
        <th class="w-10 border-t border-black border-b text-sm">작가명</th>
        <th class="w-10 border-t border-black border-b text-sm">이벤트 코드</th>
        <th class="w-10 border-t border-black border-b text-sm">이벤트 카테고리</th>
        <th class="w-10 border-t border-black border-b text-sm">고객 이메일</th>
      </tr>
    </thead>
    <tbody v-if="registerList.data.content == 0">
      <tr class="border-b border-grey-300 text-center cursor-pointer text-xs">
        <td colspan="8">검색 결과가 없습니다.</td>
      </tr>
    </tbody>

    <tbody v-else>
      <tr
        class="border-b border-grey-300 text-center cursor-pointer text-xs"
        v-for="(item, index) in registerList.data.content"
        :key="index"
      >
        <td class="bg-100">
          <div class="flex items-center justify-center">
            <input
              type="checkbox"
              class="w-4 h-4 checkbox block mr-2 rounded border-gray-300 text-blue-sky focus:ring focus:ring-offset-0 focus:ring-blue-sky focus:ring-opacity-10"
              :checked="item.checked"
              @click="(e) => handleCheck(e, index)"
            />
            {{ item.registerTokenId || '-' }}
          </div>
        </td>
        <!-- Product Id -->
        <td>
          <div>{{ item.productId }}</div>
        </td>

        <!-- 멤버 ID -->
        <td>
            <div>{{ item.memberId }}</div>
        </td>

        <td>
            <div>{{ item.titleKr }}</div>
        </td>

        <td>
            <div>{{ item.artistNameKr }}</div>
        </td>
        <td>
            <div>{{ item.eventCode }}</div>
        </td>
        <td>
            <div>{{ item.eventCategory }}</div>
        </td>
        <td>
            <div>{{ item.email }}</div>
        </td>
      </tr>
    </tbody>
  </table>


    <div class="mt-10 flex">
        
            <button
                class="w-40 h-9 button-shadow mr-10 inline-flex text-red-500 items-center justify-center border border-red-500 text-sm bg-white"
                @click="handleDelete"
            >
                <IconBase>
                <RemoveIcon></RemoveIcon>
                </IconBase>
                <span class="ml-2">삭제</span>
            </button>
            
        <Pagination
            :initialPage="filterParams.page"
            :pageSize="parseInt(filterParams.pageSize)"
            :onChange="handleChangePage"
            :onChangePageSize="handleChangePageSize"
            :total="parseInt(registerList.data.totalElements)"
        ></Pagination>
    </div>
  </Container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, reactive, toRef } from 'vue';
import { useRoute } from 'vue-router';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import ListFilter from './ListFilter.vue';
import partnerAPI from '@/service/partnerAPI';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';
import { data } from 'msw/lib/types/context';

export type RegisterListParams = {
  key?: any;
  keyword?:any;
  page: number;
  pageSize: number;
};

export default defineComponent({
  name: 'registerTokenList',
  components: {
    Container,
    ListFilter,
    Pagination,
    IconBase,
    RemoveIcon,
    CheckCircleIcon
  },
  props: {},

  setup() {
    const route = useRoute();
    const query = route.query;

    const registerCreateData = ref({
      productId : '',
      memberId :  ''
    });

    const filterParams = reactive<RegisterListParams>({
      keyword: (query.keyword as string) || '',
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
    });

    const registerList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    }) as any;


    const handleChangeFilterParams = (key: string, value: any) => {
      console.log('key : ',key)
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };
    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };

    const handleAllCheck = (e) => {
      console.log(registerList.value.data.content)
      // console.log(data)
      registerList.value.data.content = registerList.value.data.content.map(data => {
        return {
          ...data,
          checked: e.target.checked
        };
      });
    };

    // 체크 박스 event
    const handleCheck = (e, idx) => {
      console.log('cc', e.target.checked, idx);
      registerList.value.data.content[idx].checked = e.target.checked;
    };

    // 레지스터 토큰 등록
    const handleRegister = async (inputData) => {
      // 유효성 검사
      if(inputData.productId=='') {
        alert('Product ID를 입력해 주세요.')
        return false;
      } else if(inputData.memberId == '') {
        alert('Member ID를 입력해 주세요.')
        return false;
      }

      try {
        const { data } = await partnerAPI.adminRegisterToken.adminRegisterTokenCreate({
          adminRegisterTokenCreateDTOList : [inputData]
        });
        fetchRegisterList();
        alert('등록 되었습니다.');
      }catch (e) {
        alert(e.response.data.message)
        console.error(e);
      }
    }

    // 토큰 삭제
    const handleDelete = async () => {
      try {
        // 체크 되어 있는 레지스터 리스트 변수화
        const checkedData = registerList.value.data.content.filter(d => d.checked === true).map(d => d.registerTokenId) || [];
        console.log(checkedData)
        if (checkedData.length === 0) {
          alert('선택된 행이 없습니다.');
          return;
        }
        if(window.confirm('삭제 하시겠습니까?') == false) return false;
        await partnerAPI.adminRegisterToken.adminRegisterTokenDelete({
          registerTokenIds: checkedData
        })

        fetchRegisterList();
        alert('삭제 되었습니다.');
      } catch (e) {
        console.error(e);
      }
    };


    /**
     * 검색 실행
     */
    const executeSearch = () => {
      router.push({
        path: window.location.pathname,
        query: {
          keyword: filterParams.keyword || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
        },
      });
    };

    // 검색
    const getAppliedFilterParams = () => {
      const paramData = {
        page: filterParams.page,
        pageSize: filterParams.pageSize,
      }
      // 검색 조건에 값이 존재할 경우
      if(filterParams.keyword != ''){
        paramData[filterParams.key] = filterParams.keyword
      }
      console.log(filterParams.key)
      return paramData;
    }

    const fetchRegisterList = async () => {
      console.group('fetchRegisterList');
      console.log('finalFilterParams', getAppliedFilterParams());
      console.groupEnd();
        console.log(partnerAPI)
      try {
        const { data } = await partnerAPI.adminRegisterToken.adminRegisterTokenFind(
            getAppliedFilterParams()
        );
        registerList.value = data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };

    onMounted(fetchRegisterList);

    watch([filterParams], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchRegisterList();
    });

    return {
      filterParams,
      registerList,
      handleCheck, // 삭제 checkbox
      handleDelete, // 삭제 메소드
      handleRegister, // 생성 메소드
      registerCreateData, // 생성 param
      handleChangePage,
      handleChangePageSize,
      executeSearch,
      handleChangeFilterParams,
      fetchRegisterList,
      handleAllCheck
    };
  },
});
</script>

<style lang="scss">
@import "../../../assets/style/label";

.ListTable {
  tbody {
    tr {
    }
  }

  td {
    padding: 8px 10px;
  }
}


</style>
